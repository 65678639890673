/*
 * @Author: 刘康宁 869161591@qq.com
 * @Date: 2022-09-22 19:07:40
 * @LastEditors: YGQ
 * @LastEditTime: 2025-03-20 18:55:41
 * @FilePath: /oneid/src/api/host.js
 * @Description: 判断线路
 */
import Vue from 'vue'
const HOST_LIST = [
  {
    env: 'release',  // 发布版接口地址 
    name: '默认线路',
    code: 'MR',
    url: 'https://oneid.geemro.com/', // 前端地址
    host: 'https://oneid.geemro.com/oneid-php/public/index.php/' // 后端地址
  }, {
    env: 'release', // 发布版备用接口地址 
    name: '备用线路',
    code: 'BY',
    url: 'https://oneid.geemro.cn/',
    host: 'https://oneid.geemro.cn/oneid-php/public/index.php/'
  },{
    env: 'release', // 发布版备用接口地址 
    name: '测试线路',
    code: 'BY2',
    url: 'http://oneid2.geemro.com/',
    host: 'http://oneid2.geemro.com/oneid-php/public/index.php/'
  },
  //? 测试线路, 勿删
  {
    env: 'trial',
    name: '测试环境(trial)',
    code: 'MR',
    url: 'https://trial.geemro.com/',
    host: 'https://trial.geemro.com/oneid-php/public/index.php/'
  },
  //? 开发线路, 勿删
  {
    env: 'develop',
    name: '开发环境(dev)',
    code: 'MR',
    url: 'https://dev.geemro.com/',
    host: 'https://dev.geemro.com/oneid-php/public/index.php/'
  },
  //? 本地线路, 仅供本地开发使用
  { env: 'local', name: '本地环境(后端dev)', code: 'MR', url: 'http://localhost:8080/', host: '/dev-proxy/' },
  { env: 'local', name: '本地环境(后端trial)', code: 'CS', url: 'http://192.168.2.99:8080/', host: '/trial-proxy/' },
  { env: 'local', name: '本地环境(后端release)', code: 'SC', url: 'http://127.0.0.1:8080/', host: '/release-proxy/' },
]

// 获取当前环境, 仅执行一次并存储入全局变量 $ENV
function getEnvironment () {
  const { domain } = document
  if (domain.includes('oneid2.geemro.com')) return 'release' // 判断生产环境
  if (domain.includes('oneid.geemro.com') || domain.includes('oneid.geemro.cn') || domain.includes('pms.geemro.com') || domain.includes('pms.geemro.cn')) return 'release' // 判断生产环境
  if (domain.includes('trial.oneid') || domain.includes('trial.geemro')) return 'trial' // 判断体验环境
  if (domain.includes('dev.geemro.com')) return 'develop' // 判断开发环境
  if (domain.includes('localhost') || domain.includes('192.168') || domain.includes('127.0')) return 'local' // 判断本地环境
  console.error('当前部署环境异常')
  return 'mock'
}
Vue.prototype.$ENV = getEnvironment()
console.log('初始化环境和线路');

initHostCode()
function initHostCode () {
  const hostList = getHostList() // 筛选出当前环境可用的线路
  localStorage.HOST_CODE = 'MR'
  hostList.map(e => {
    if (window.location.href.indexOf(e.url) != -1) localStorage.HOST_CODE = e.code
  })
}

// 筛选出当前环境可用的线路
function getHostList () {
  return HOST_LIST.filter(e => e.env == Vue.prototype.$ENV)
}

// 从本地存储中读取当前选取的主机代码
function getHost () {
  if (!(HOST_LIST.map(e => e.code).includes(localStorage.HOST_CODE))) localStorage.HOST_CODE = 'MR' // 缺省值MR
  const hostList = getHostList() // 筛选出当前环境可用的线路
  const item = hostList.find(e => e.code == localStorage.HOST_CODE)
  return item ?? hostList[0] // 找不到则返回第一个
}

// 切换线路
function toggleHost (code) {
  if (code == getHost().code) return // 避免重复
  localStorage.HOST_CODE = code // code 更新后, 重新 getHost 的结果便是新 Host
  let href = window.location.href  // 跳转到指定的前端线路 //? 不同项目语法不一致, 下面的替换语法需定制
  if (href.indexOf('?') != -1) {
    href = href.replace(/^http(.*)\?/g, getHost().url + '?') // 携带 query 参数跳转
  } else {
    href = getHost().url // 不携带 query 跳转
  }
  const loading = Vue.prototype.$loading({ text: '线路切换中', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
  setTimeout(() => window.location.href = href, 500); // 为了不让菜单组件卡住不动
  setTimeout(() => loading.close(), 8000);
  // setTimeout(() => Vue.prototype.$msgbox('线路无响应, 请稍后再试。', '切换超时').then(() => loading.close()), 3000); // 需要一个 ping 接口才行
}

export default { getHostList, getHost, toggleHost }
